<script>
import api from '@/command/api'
import Point from './Point'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction, delAction } from '@/command/netTool'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

const getChildren = function getChildren(children) {
  return children.map(e => {
    return {
      ...e,
      label: e.name,
      value: String(e.id),
      children: e.children ? getChildren(e.children) : null
    }
  })
}

export default {
  name: 'pickUp',
  data() {
    return {
      ...api.command.getState(),
      tableData: [],
      cascaderList: [],
      dataForm: {
        jwd: null,
        longitude: null,
        latitude: null,
        name: null,
        address: null,
        ssq: []
      }
    }
  },
  mounted() {
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'cascaderList'
    })

    this.getDataInit()
  },
  methods: {
    getDataInit() {
      api.command.getList.call(this, {
        url: '/base/farmPickupAddress/page',
        current: 1
      })
    },
    getHeader() {
      return [
        {
          name: '地址',
          type: 'input',
          key: 'address'
        },
        {
          label: '',
          type: 'text',
          key: 'a1'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'linkPhone',
          title: '电话',
          align: 'left'
        },
        {
          dataIndex: 'address',
          title: '地址',
          align: 'left',
          customRender: function(text, records) {
            return records.address == null
              ? ''
              : `${records.provinceName + ' ' + records.cityName + ' ' + records.areaName + ' ' + text}`
          },
          onExport: (text, records) => {
            return `${records.provinceName + records.cityName + records.areaName + text}`
          }
        },

        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  let obj = {
                    ...records,
                    ssq: [records.provinceCode, records.cityCode, records.areaCode],
                    jwd: `${records.longitude + '，' + records.latitude}`
                  }
                  this.handleEditPoint(obj)
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () => {
                  delAction(`/base/farmPickupAddress?id=${records.id}`).then(result => {
                    if (result.code == 200) {
                      this.getDataInit()
                      this.$message.success('删除成功')
                    } else {
                      this.$message.error(result.msg)
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          icon: 'plus',
          type: 'primary',
          onClick: () => {
            this.handleEditPoint()
          }
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/base/farmPickupAddress/deleteBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    },
    handleEditPoint(pontData = this.dataForm) {
      let dataObj = JSON.parse(JSON.stringify(pontData))

      apiTool.showDrawer({
        title: '提货点管理',
        width: '680px',
        view: Point,
        viewProps: {
          dataForm: dataObj,
          cascaderList: this.cascaderList
        },
        success: ({ data, setHidden }) => {
          let arr = data.jwd.split('，')
          data.longitude = arr[0]
          data.latitude = arr[1]
          if (data.id) {
            putAction('/base/farmPickupAddress', {
              ...data
            }).then(result => {
              if (result.code == 200) {
                this.getDataInit()
                this.$message.success('编辑成功')
                setHidden()
              } else {
                this.$message.error(result.msg)
              }
            })
          } else {
            postAction('/base/farmPickupAddress', {
              ...data
            }).then(result => {
              if (result.code == 200) {
                this.getDataInit()
                this.$message.success('添加成功')
                setHidden()
              } else {
                this.$message.error(result.msg)
              }
            })
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    handleMapPreview() {
      return [
        {
          name: '地图预览',
          type: 'primary',
          onClick: () => {
            const markerList = this.records.map(e => ({
              lng: e.lon,
              lat: e.lat
            }))

            apiTool.openMapMarkers({
              title: '地图标记点',
              value: markerList
            })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        rightButton={this.handleMapPreview()}
        records={this.records}
      />
    )
  }
}
</script>
